import React from 'react';

import logo from '../../assets/logo-splash.svg';
import { Container, Logo } from './styles';

const Loading = () => (
  <Container>
    <Logo src={logo} alt="logo" />
  </Container>
);

export default Loading;
