/* eslint-disable no-console */
import * as axios from 'axios';
import * as localForage from 'localforage';

const { REACT_APP_API_URL,REACT_APP_TEXTRACT_URL,REACT_APP_S3_URL } = process.env;

const defaultConfig = {
  baseURL: REACT_APP_API_URL,
  headers: {
    'content-type': 'application/json',
    'Access-Control-Allow-Origin': '*',
  },
};

export const S3_URL = REACT_APP_S3_URL;

export async function textractAPI(body) {
  let response = {};

  try {
    response = await fetch(REACT_APP_TEXTRACT_URL, {
      method: 'POST',
      body: JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
    }).then((res) => {
      if (res.status === 500) {
        throw new Error('Algo deu errado');
      }

      return res.json();
    });
    return response;
    
  } catch (e) {
    return {
      message: 'Algo deu errado, tente editar o campo!',
      error: true,
    };
  }
}

export const unathenticatedInstance = axios.create(defaultConfig);
export const authenticatedInstance = axios.create(defaultConfig);

const refreshTokenExpiredCallback = async () => {
  console.error('Refresh token expired');
  await localForage.removeItem('AUTH_REFRESH_TOKEN');
  await localForage.removeItem('AUTH_TOKEN');
  window.location.href = '/login?error=expired+token';
  return null;
};

export default {
  /**
   * Makes an unauthenticated request
   * @returns {import('axios').AxiosInstance}
   */
  unauthorized() {
    unathenticatedInstance.interceptors.response.use(
      (response) => response,
      async (error) => Promise.reject(error),
    );

    return unathenticatedInstance;
  },

  /**
   * Makes a authenticated request
   * @returns {import('axios').AxiosInstance}
   */
  async authorized() {
    const authToken = await localForage.getItem('AUTH_TOKEN');

    authenticatedInstance.defaults.headers.common.Authorization = `Bearer ${authToken}`;
    authenticatedInstance.interceptors.response.use(
      (response) => response,
      async (error) => {
        // Refresh Token
        if (
          error.response?.status === 401 &&
          error.response?.data?.detail === 'Expired Token'
        ) {
          console.log('Token has expired, trying to renew...');

          try {
            const refreshToken = await localForage.getItem(
              'AUTH_REFRESH_TOKEN',
            );

            const { data } = await authenticatedInstance.post(
              '/auth/refresh-token',
              {
                refresh_token: refreshToken,
              },
            );

            if (data?.token && !data?.error_code) {
              await localForage.setItem('AUTH_TOKEN', data.token);
              await localForage.setItem(
                'AUTH_REFRESH_TOKEN',
                data.refresh_token,
              );
              authenticatedInstance.defaults.headers.common.Authorization = `Bearer ${data.token}`;
              console.log('Token sucessfully renewed!');

              try {
                const newConfig = {
                  ...error.response.config,
                };
                newConfig.headers.Authorization = `Bearer ${data.token}`;
                const newResponse = authenticatedInstance(newConfig);
                return newResponse; // Maybe send Promise.resolve(newResponse);
              } catch (newError) {
                console.error(
                  'Error while making request with the renewed token',
                  newError,
                );
                await localForage.removeItem('AUTH_REFRESH_TOKEN');
                await localForage.removeItem('AUTH_TOKEN');
                window.location.href = '/login?error=expired+token';
                return null;
              }
            } else {
              return refreshTokenExpiredCallback();
            }
          } catch (err) {
            return refreshTokenExpiredCallback();
          }
        } else if (error.response?.status === 401) {
          return refreshTokenExpiredCallback();
        }

        return Promise.reject(error);
      },
    );

    return authenticatedInstance;
  },
};
