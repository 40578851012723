import React, { useState, useMemo, useEffect, useContext } from 'react';

import { useRequest } from 'ahooks';
import * as localForage from 'localforage';
import { useQueryClient } from 'react-query';
import { toast } from 'react-toastify';

import request from '../helpers/request';

const initialValues = {
  authenticated: null,
  token: null,
  user_id: null,
};

const AuthContext = React.createContext(initialValues);

function AuthProvider(props) {
  const [authenticated, setAuthenticated] = useState(null);
  const [user, setUser] = useState({});
  const [auth, setAuth] = useState();
  const queryClient = useQueryClient();

  const getInitialValue = async () => {
    const token = await localForage.getItem('AUTH_TOKEN');
    const userData = await localForage.getItem('USER');

    if (userData?.id && typeof token === 'string' && !!token) {
      setUser(userData);
      setAuthenticated(true);
      return;
    }

    setAuthenticated(false);
  };

  useEffect(() => {
    getInitialValue();
  }, []);

  /** login */
  const { data: response, run: loginRequest } = useRequest(
    async (values) => {
      try {
        const instance = await request.unauthorized();
        return await instance.post('/auth/login', values);
      } catch (err) {
        if (err?.response?.data?.error_code === 'ERR.0.0003') {
          toast.error('Usuário ou senha incorretos');
        } else if (err?.response?.data?.error_code === 'ERR.0.0004') {
          toast.error('Usuário não habilitado');
        } else {
          toast.error('Erro no servidor. Tente novamente mais tarde');
        }
        return null;
      }
    },
    {
      manual: true,
    },
  );

  useEffect(() => {
    if (response && response.data && response.status === 200) {
      setAuth(response?.data);
      setUser({ ...response?.data?.user });
    }
  }, [response]);

  useEffect(() => {
    if (auth) {
      localForage.setItem('AUTH_REFRESH_TOKEN', auth.refresh_token);
      localForage.setItem('AUTH_TOKEN', auth.token);
      localForage.setItem('USER', user);

      setAuthenticated(true);
    }
  }, [auth]);

  const logout = async () => {
    localForage.removeItem('USER');
    localForage.removeItem('AUTH_REFRESH_TOKEN');
    localForage.removeItem('AUTH_TOKEN');

    queryClient.invalidateQueries();

    setAuthenticated(false);
    setUser({});
  };

  const updateUser = async (userData) => {
    setUser(userData);
    await localForage.setItem('USER', userData);
  };

  /** provider */
  const value = useMemo(
    () => ({
      authenticated,
      user,
    }),
    [authenticated, user],
  );

  return (
    <AuthContext.Provider
      value={{ ...value, login: loginRequest, logout, updateUser }}
      {...props}
    />
  );
}

export const useAuth = () => useContext(AuthContext);

export default AuthProvider;
