import React, { Suspense } from 'react';

import { ThemeProvider } from '@material-ui/core/styles';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import AuthProvider from './contexts/AuthProvider';
import PageLoading from './newPages/Loading';
import Routes from './routes';
import GlobalStyle from './styles/globalStyle';
import theme from './styles/theme';

import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <AuthProvider>
        <div className="App">
          <BrowserRouter>
            <Suspense fallback={<PageLoading />}>
              <Routes />
            </Suspense>
          </BrowserRouter>
          <ToastContainer position="bottom-right" />
          <GlobalStyle />
        </div>
      </AuthProvider>
    </ThemeProvider>
  );
}

export default App;
