const colors = {
  primaryColor: '#0099FF',
  darkBlue: '#005AC6',
  white: '#FFF',
  black: '#000',
  grayLighter: '#F4F4F4',
  gray: '#707070',
  grayOpacity: 'rgba(0, 0, 0, 0.4)',
  grayStronger: '#B3B3B3',
  grayStrongest: '#999999',
  green: '#08DE00',
  boxShadow: '#00000024',
  buttonShadow: '#383838',
  buttonDisabled: '#B3B3B3',
  red: '#E70000',
  orange: '#F58431',
};

export default colors;
