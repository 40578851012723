import React, { lazy } from 'react';

import { Switch, Route, Redirect } from 'react-router-dom';

import { useAuth } from '../contexts/AuthProvider';
import PageLoading from '../pages/Loading';

const LazyRecoveryPassword = lazy(() => import('../pages/RecoveryPassword'));
const LazyRecoveryPasswordValidation = lazy(() =>
  import('../pages/RecoveryPasswordValidation'),
);
const LazyLogin = lazy(() => import('../newPages/Login'));
const LazyRegistration = lazy(() => import('../newPages/Registration'));

const LazyProtected = lazy(() => import('./Protected'));

function Main() {
  const { authenticated } = useAuth();

  if (authenticated === null) {
    return <PageLoading />;
  }

  if (authenticated === false) {
    return (
      <Switch>
        <Route path="/login" component={LazyLogin} />
        <Route path="/cadastro" component={LazyRegistration} />
        <Route path="/esqueci-a-senha" component={LazyRecoveryPassword} exact />
        <Route
          path="/esqueci-a-senha/validacao"
          component={LazyRecoveryPasswordValidation}
          exact
        />
        <Route render={() => <Redirect to="/login" />} />
      </Switch>
    );
  }

  return (
    <Switch>
      <Route path="/login" exact render={() => <Redirect to="/" />} />
      <Route path="*" component={LazyProtected} />
    </Switch>
  );
}

export default Main;
